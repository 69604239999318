import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl"

const content = {
  title: "Article Writing Services",
  whatWeOffer: {
    leftPart: "High-quality articles are not easy to come by. Writing one is a delicate art that requires precision, knowledge, and talent. However, above all else, in the online marketing sphere, it requires consistency.",
    rightPart: "As a content writing agency, our goal is to provide article writing services that are custom-tailored to our client's specific needs. Our writing staff will adhere to your particular instructions, and our team of editors will take care of everything in terms of grammar, style, and semantics."
  },
  processText: "The final product is a fresh-out-of-the-oven article that's ready for posting. Contact us for a quote.",
  testimonials: testimonials
}

const meta = {
  title: "Article Writing Services",
  description: "High-quality articles are not easy to come by. Writing one is a delicate art that requires precision, knowledge, and talent. However, above all else, in the online marketing sphere, it requires consistency."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/article-writing";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)